<template>
  <div>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-h5 font-weight-light">
          <div><v-icon left class="mb-1">fas fa-filter</v-icon>Filtro</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="form" @submit.prevent="getEmpresas(filtro)">
            <v-row>
              <v-col cols="12" sm="4" md="3">
                <v-text-field
                  v-model.trim="filtro.nombre"
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="2">
                <v-text-field
                  v-model.trim="filtro.abreviacion"
                  label="Abreviación"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="2">
                <v-text-field
                  v-model.trim="filtro.cuit"
                  label="CUIT"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="2">
                <v-switch
                  v-model="filtro.inhabilitado"
                  label="Inhabilitada"
                ></v-switch>
              </v-col>
              <v-col cols="6" sm="1" class="d-flex align-center justify-start">
                <v-btn type="submit" color="info" elevation="3" :loading="load">Buscar<v-icon right small>fas fa-search</v-icon></v-btn>
                <div class="ml-6"><LimpiarFiltro /></div>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="headers"
      :items="empresas_array"
      class="elevation-1 mt-6"
      :loading="load"
    >
      <template v-slot:top>
        <div class="d-flex py-2 px-3">
          <v-spacer></v-spacer>
          <v-dialog
            persistent
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-start justify-end mt-n7">
                <v-btn
                  color="success"
                  fab
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>fas fa-plus</v-icon>
                </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-form ref="form">
                  <v-text-field
                    v-model.trim="editedItem.nombre"
                    :rules="[rules.required]"
                    label="Nombre"
                    validate-on-blur
                  ></v-text-field>

                  <v-row :no-gutters="$vuetify.breakpoint.xs">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model.trim="editedItem.abreviacion"
                        :rules="[rules.required]"
                        label="Abreviación"
                        validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model.trim="editedItem.cuit"
                        :rules="[rules.required]"
                        label="CUIT"
                        validate-on-blur
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-switch
                    v-model="editedItem.inhabilitado"
                    label="Inhabilitada"
                  ></v-switch>
                </v-form>
              </v-card-text>

              <v-card-actions class="d-flex justify-end pb-4">
                <v-btn
                  color="error"
                  class="mr-4"
                  :disabled="load"
                  @click="dialog = false"
                >
                  Cancelar
                </v-btn>
                <Confirmar :loading="load" @action="save"/>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          color="primary"
          @click="editItem(item)"
        >
          fas fa-pen
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-alert
          class="mx-auto my-3"
          max-width="400"
          border="left"
          dense
          text
          type="warning"
        >
          No hay datos para los filtros seleccionados
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { db } from '../../firebase'
import { mapState, mapActions } from 'vuex'
import LimpiarFiltro from '../../components/utils/LimpiarFiltro'
import Confirmar from '../../components/utils/Confirmar.vue'

export default {
  data() {
    return {
      panel: 0,
      dialog: false,
      load: false,
      headers: [
        { text: 'Nombre', value: 'nombre'},
        { text: 'Abreviación', value: 'abreviacion', sortable: false },
        { text: 'CUIT', value: 'cuit', sortable: false },
        { text: 'Editar', value: 'actions', align: 'center', sortable: false },
      ],
      empresas_array: [],
      rules: { required: value => !!value || 'Campo requerido' },
      editedIndex: -1,
      editedItem: {
        id: '',
        nombre: '',
        abreviacion: '',
        cuit: '',
        inhabilitado: false,
      },
      defaultItem: {
        id: '',
        nombre: '',
        abreviacion: '',
        cuit: '',
        inhabilitado: false,
      },
    }
  },
  created() {
    this.empresas_array = this.empresas
  },
  components: {
    LimpiarFiltro, Confirmar
  },
  computed: {
    ...mapState(['filtro', 'empresas']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nueva Empresa' : 'Editar Empresa'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    ...mapActions(['get_empresas']),
    async getEmpresas(filtro) {
      this.load = true
      this.empresas_array = []

      if (filtro.inhabilitado) {
        await db.collection('empresas').where('inhabilitado', '==', true).get()
          .then((res) => {
            res.forEach((doc) => {
              let empresa = doc.data()
              empresa.id = doc.id
              this.empresas_array.push(empresa)
            })
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.empresas_array = this.empresas
      }

      this.load = false
      this.empresas_array = this.empresas_array.filter(empresa => this.removeAccents(empresa.nombre).indexOf(this.removeAccents(filtro.nombre)) >= 0)
      this.empresas_array = this.empresas_array.filter(empresa => empresa.abreviacion.toLowerCase().indexOf(filtro.abreviacion.toLowerCase()) >= 0)
      this.empresas_array = this.empresas_array.filter(empresa => empresa.cuit.indexOf(filtro.cuit) >= 0)

    },
    async save () {
      if (this.$refs.form.validate()) {

        this.load = true

        if (this.editedIndex > -1) {
          // edicion
          await db.collection('empresas').doc(this.editedItem.id)
            .update({
              abreviacion: this.editedItem.abreviacion,
              cuit: this.editedItem.cuit,
              inhabilitado: this.editedItem.inhabilitado,
              nombre: this.editedItem.nombre, 
            })
            .then(() => {
              Object.assign(this.empresas_array[this.editedIndex], this.editedItem)
            })
            .catch((error) => {
              console.error(error)
            })
            
        } else {
          // creacion
          await db.collection('empresas')
            .add({
              abreviacion: this.editedItem.abreviacion,
              cuit: this.editedItem.cuit,
              inhabilitado: this.editedItem.inhabilitado,
              nombre: this.editedItem.nombre, 
            })
            .then((docRef) => {
              this.editedItem.id = docRef.id
              this.empresas_array.push(this.editedItem)
            })
            .catch((error) => {
              console.error(error)
            })
        }

        await this.get_empresas()

        this.load = false
        this.dialog = false
      }
    },
    editItem (item) {
      this.editedIndex = this.empresas_array.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close () {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$refs.form.resetValidation()
    },
    removeAccents (str) {
      return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }
  },
}
</script>